import createCrudReducer from 'src/reducers/createCrudReducer';

import Estado from 'src/model/Estado';

const estadosReducer = createCrudReducer<number, Estado>('estados', 'estados');

export const {
  actions,
  slice: { reducer }
} = estadosReducer;

export default estadosReducer;
