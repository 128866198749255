import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import SplashScreen from 'src/components/SplashScreen';
import useAuth from 'src/hooks/useAuth';
import AppBar from 'src/components/AppBar';
import Account from 'src/layouts/CartoriosLayout/Actions/Account';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 54
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const { user } = useAuth();

  const [appbarShadow, setAppbarShadow] = useState(false);

  if (!user) {
    return <SplashScreen />;
  }
  return (
    <div className={classes.root}>
      <AppBar
        hasNavBar={false}
        style={{
          backgroundColor: 'transparent',
          boxShadow: appbarShadow
            ? '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)'
            : 'none'
        }}
        actions={
          <Box ml={2}>
            <Account />
          </Box>
        }
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <PerfectScrollbar
              onScrollY={(container) => {
                if (container.scrollTop > 0 && !appbarShadow) {
                  setAppbarShadow(true);
                } else if (container.scrollTop === 0 && appbarShadow) {
                  setAppbarShadow(false);
                }
              }}
            >
              {children}
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardLayout;
