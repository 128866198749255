import createCrudReducer from 'src/reducers/createCrudReducer';

const processamentoDeFaturasReducer = createCrudReducer(
  'processamentoDeFaturas',
  'processamentoDeFaturas'
);
export const {
  actions,
  slice: { reducer }
} = processamentoDeFaturasReducer;

export default processamentoDeFaturasReducer;
