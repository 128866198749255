import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';

const AuthGuard = ({ allowedRoles, children }) => {
  const { isAuthenticated, user, logout } = useAuth();
  const targetRedirect = window.location;

  if (!isAuthenticated) {
    return <Redirect to={`/login?&redirect=${targetRedirect}`} />;
  }

  if (
    allowedRoles &&
    !user.roles?.some((r) =>
      allowedRoles.some((a) => a.toUpperCase() === r.normalizedName)
    )
  ) {
    logout();
    return <Redirect to="/login" />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
