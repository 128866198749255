import * as Yup from 'yup';
import * as validation from 'src/utils/cpf_cpnj';

Yup.addMethod(Yup.string, 'cpf', (message, required = true) =>
  Yup.string().test('teste_cpf', message, (value) =>
    validation.isValidCpf(value, required)
  )
);

Yup.addMethod(Yup.string, 'cnpj', (message, required = true) =>
  Yup.string().test('teste_cnpj', message, (value) =>
    validation.isValidCnpj(value, required)
  )
);

Yup.addMethod(Yup.number, 'emptyStringToNull', () =>
  Yup.number()
    .nullable(true)
    .transform((value, originalValue) =>
      String(originalValue).trim() === '' ? null : value
    )
);

Yup.addMethod(Yup.string, 'emptyStringToNull', () =>
  Yup.string()
    .nullable(true)
    .transform((value, originalValue) =>
      String(originalValue).trim() === '' ? null : value
    )
);
