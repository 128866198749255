/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'src/store';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Popover,
  SvgIcon,
  Tooltip,
  Typography
} from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';

import importsReducer from 'src/reducers/importsReducer';

import { Users as UsersIcon } from 'react-feather';
import StoreIcon from '@material-ui/icons/Store';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';

const iconsMap = {
  funcionario: UsersIcon,
  cartorio: StoreIcon,
  unknown: HelpOutlineIcon
};

const useStyles = makeStyles(() => ({
  popover: {
    width: 320
  }
}));

function Exports() {
  const classes = useStyles();
  const importsRequests =
    useSelector((state) => state.imports.itemRequests) ?? [];
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Importações">
        <IconButton ref={ref} onClick={handleOpen}>
          <SvgIcon>
            <BackupIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h5" color="textPrimary">
            Importações
          </Typography>
        </Box>
        {importsRequests?.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              Não ha nenhuma importação
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {importsRequests?.map((exp) => {
                const type = exp.info?.type ?? 'unknown';
                const Icon = iconsMap[type];

                return (
                  <ListItem
                    component={RouterLink}
                    divider
                    id={`${type}-${exp.data.id ?? 'id'}`}
                    to="#"
                  >
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText
                      primary={exp.info?.nome ?? 'Sem nome'}
                      primaryTypographyProps={{
                        variant: 'subtitle2',
                        color: 'textPrimary'
                      }}
                      secondary={
                        exp.response ? (
                          exp.response.status.toString()[0] === '2' ? (
                            <Box color="success.main">Sucesso</Box>
                          ) : (
                            <Box color="error.main">Erro</Box>
                          )
                        ) : (
                          <Box color="text.secondary">Enviando...</Box>
                        )
                      }
                    />

                    <ListItemSecondaryAction>
                      {exp.response ? (
                        exp.response.status.toString()[0] === '2' ? (
                          <IconButton
                            onClick={() =>
                              dispatch(importsReducer.actions.cancel(exp))
                            }
                            edge="end"
                          >
                            <CheckIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() =>
                              dispatch(importsReducer.actions.cancel(exp))
                            }
                            edge="end"
                          >
                            <CloseIcon />
                          </IconButton>
                        )
                      ) : (
                        <CircularProgress />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
            <Box p={1} display="flex" justifyContent="center">
              <Button
                component={RouterLink}
                size="small"
                to="#"
                onClick={() =>
                  dispatch(importsReducer.actions.cancelAll(importsRequests))
                }
              >
                Remover todas
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
}

export default Exports;
