/* eslint-disable no-unused-vars */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import 'src/utils/yupMethods';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// noinspection ES6UnusedImports
import { enableES5 } from 'immer';
import store from 'src/store';
import App from 'src/App';

// eslint-disable-next-line no-extend-native
Date.prototype.toISOString = Date.prototype.toLocaleString;

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById('root')
);
