export function objFromArray<
  Key extends number | string,
  Obj extends { id?: Key }
>(arr: Obj[]): Record<Key | string, Obj> {
  const record = {} as Record<Key | string, Obj>;
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    record[obj.id ?? 'undefined'] = obj;
  }
  return record;
}
export default objFromArray;
