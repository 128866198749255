import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { parseQuery } from 'src/utils/parsers/queryString';
import useAuth from '../../hooks/useAuth';
import Usuario from 'src/model/Usuario';

const GuestGuard = ({ children }) => {
  const { user }: { user: Usuario } = useAuth() as any;
  const { redirect } = parseQuery(window.location.search);

  if (redirect && user) {
    window.location.href = redirect as string;
    return <></>;
  }

  if (user?.type === 'funcionario') {
    return <Redirect to="/administrativo" />;
  }
  if (user?.type === 'cartorio') {
    return <Redirect to="/clientes" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
