import createCrudReducer from 'src/reducers/createCrudReducer';
import axios from 'src/utils/axios';
import Boleto from 'src/model/Boleto';

const actionsBuilder = (slice) => ({
  cancelar: (boletoId) => async (dispatch) => {
    await axios.post(`boletos/${boletoId}/cancelar`);
    const response = await axios.get(`boletos/${boletoId}`);

    dispatch(slice.actions.update(response.data));
  }
});

const boletosReducer = createCrudReducer<
  number,
  Boleto,
  {},
  ReturnType<typeof actionsBuilder>
>('boletos', 'boletos', {
  actionsBuilder
});
export const {
  actions,
  slice: { reducer }
} = boletosReducer;
