import createCrudReducer from 'src/reducers/createCrudReducer';
import Feriado from 'src/model/Feriado';

const feriadosReducer = createCrudReducer<
  number,
  Feriado,
  Feriado,
  Feriado,
  Feriado,
  Feriado
>('feriados', 'feriados');
export const {
  actions,
  slice: { reducer }
} = feriadosReducer;
