import React from 'react';

const Logo = (props) => (
  <img
    alt="Logo"
    src={`${process.env.PUBLIC_URL}/static/logo-big.png`}
    {...props}
  />
);

export default Logo;
