import createCrudReducer from 'src/reducers/createCrudReducer';
import axios from 'src/utils/axios';
import { Dispatch } from '@reduxjs/toolkit';
import ImobAssinatura from 'src/model/assinatura/imob/ImobAssinatura';

export interface ImobAssinaturasActions {
  sincronizarImob: (
    imobAssinaturaId: number,
    cancelToken: any
  ) => (dispatch: Dispatch) => any;
}

const imobAssinaturasReducer = createCrudReducer<
  number,
  ImobAssinatura,
  {},
  ImobAssinaturasActions
>('imobAssinaturas', 'imobAssinaturas', {
  actionsBuilder: (slice) => ({
    sincronizarImob: (imobAssinaturaId: number, cancelToken: any) => async (
      dispatch: Dispatch
    ) => {
      await axios.post(
        `imobAssinaturas/${imobAssinaturaId}/sincronizacao`,
        undefined,
        {
          timeout: 1200000,
          cancelToken: cancelToken
        }
      );
      const response = await axios.get(`imobAssinaturas/${imobAssinaturaId}`);
      dispatch(slice.actions.update(response.data));
    }
  })
});
export const {
  actions,
  slice: { reducer }
} = imobAssinaturasReducer;

export default imobAssinaturasReducer;
