import createCrudReducer from 'src/reducers/createCrudReducer';

const funcionariosCrudReducer = createCrudReducer(
  'funcionarios',
  'funcionarios'
);

export const {
  actions,
  slice: { reducer }
} = funcionariosCrudReducer;

export default funcionariosCrudReducer;
