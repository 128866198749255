import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import CartoriosLayout from 'src/layouts/CartoriosLayout';
import MainLayout from 'src/layouts/MainLayout';
import AuthGuard from 'src/components/guards/AuthGuard';
import GuestGuard from 'src/components/guards/GuestGuard';
import SplashScreen from './components/SplashScreen';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<SplashScreen />}>
    <Switch>
      {routes.map((route) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={`route-${route.path}`}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const FuncionarioGuard = ({ children }) => (
  <AuthGuard allowedRoles={['funcionario']}>{children}</AuthGuard>
);

const CartorioGuard = ({ children }) => (
  <AuthGuard allowedRoles={['cartorio']}>{children}</AuthGuard>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,

    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/recuperaracesso',
    component: lazy(() =>
      import('src/views/auth/recuperar_conta/EnviarEmailDeRecuperacaoView')
    )
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/alterarsenha',
    component: lazy(() =>
      import('src/views/auth/recuperar_conta/AlterarSenhaViaCodigoView')
    )
  },
  {
    path: '/clientes',
    guard: CartorioGuard,
    layout: CartoriosLayout,
    routes: [
      {
        exact: true,
        path: '/clientes/conta/:tab(faturas)/:faturaId',
        component: lazy(() =>
          import('src/views/area_do_cliente/MinhaContaView')
        )
      },
      {
        exact: true,
        path: '/clientes/conta/:tab',
        component: lazy(() =>
          import('src/views/area_do_cliente/MinhaContaView')
        )
      },
      {
        exact: true,
        path: '/clientes/conta',
        component: () => <Redirect to="conta/faturas" />
      },
      {
        exact: true,
        path: '',
        component: () => <Redirect to="/clientes/conta/faturas" />
      }
    ]
  },
  {
    path: '/administrativo',
    guard: FuncionarioGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/administrativo/dashboard',
        component: lazy(() =>
          import('src/views/area_administrativa/dashboard/DashboardView')
        )
      },
      {
        exact: true,
        path: '/administrativo/conta',
        component: lazy(() =>
          import('src/views/area_administrativa/conta/ContaView')
        )
      },
      {
        exact: true,
        path: '/administrativo/relatorios',
        component: lazy(() =>
          import(
            'src/views/area_administrativa/relatorios/GerenciaRelatoriosView'
          )
        )
      },
      {
        exact: true,
        path: '/administrativo/cartorios',
        component: lazy(() =>
          import(
            'src/views/area_administrativa/cartorios/GerenciaCartoriosView'
          )
        )
      },
      {
        exact: true,
        path: '/administrativo/cartorios/:cartorioId(\\d+)',
        component: lazy(() =>
          import('src/views/area_administrativa/cartorios/EditaCartorioView')
        )
      },
      {
        exact: true,
        path:
          '/administrativo/cartorios/:cartorioId(\\d+)/:tab(faturas)/:faturaId',
        component: lazy(() =>
          import('src/views/area_administrativa/cartorios/EditaCartorioView')
        )
      },
      {
        exact: true,
        path: '/administrativo/cartorios/:cartorioId(\\d+)/:tab',
        component: lazy(() =>
          import('src/views/area_administrativa/cartorios/EditaCartorioView')
        )
      },
      {
        exact: true,
        path: '/administrativo/cartorios/novo',
        component: lazy(() =>
          import('src/views/area_administrativa/cartorios/CriaCartorioView')
        )
      },
      {
        exact: true,
        path: '/administrativo/cartorios/:tab',
        component: lazy(() =>
          import(
            'src/views/area_administrativa/cartorios/GerenciaCartoriosView'
          )
        )
      },
      {
        exact: true,
        path: '/administrativo/usuarios',
        component: lazy(() =>
          import(
            'src/views/area_administrativa/funcionarios/GerenciaFuncionarioPage'
          )
        )
      },
      {
        exact: true,
        path: '/administrativo/usuarios/novo',
        component: lazy(() =>
          import(
            'src/views/area_administrativa/funcionarios/CriaFuncionarioPage'
          )
        )
      },
      {
        exact: true,
        path: '/administrativo/usuarios/:funcionarioId(\\d+)',
        component: lazy(() =>
          import(
            'src/views/area_administrativa/funcionarios/EditaFuncionarioPage'
          )
        )
      },
      {
        exact: true,
        path: '/administrativo/faturas',
        component: () => <Redirect to="/administrativo/faturas/todas" />
      },
      {
        exact: true,
        path: '/administrativo/faturas/todas',
        component: lazy(() =>
          import('src/views/area_administrativa/faturas/GerenciaFaturasView')
        )
      },
      {
        exact: true,
        path: '/administrativo/faturas/todas/:periodoId',
        component: lazy(() =>
          import('src/views/area_administrativa/faturas/GerenciaFaturasView')
        )
      },
      {
        exact: true,
        path: '/administrativo/faturas/processamentos',
        component: lazy(() =>
          import(
            'src/views/area_administrativa/faturas/processamentos/GerenciaProcessamentosView'
          )
        )
      },
      {
        exact: true,
        path: '/administrativo/faturas/processamentos/:processamentoDeFaturaId',
        component: lazy(() =>
          import(
            'src/views/area_administrativa/faturas/processamentos/ProcessamentoDeFaturaDetalhesView'
          )
        )
      },
      {
        exact: true,
        path: '/administrativo/faturas/configuracoes',
        component: lazy(() =>
          import(
            'src/views/area_administrativa/faturas/configuracoes/ConfiguracoesFaturasView'
          )
        )
      },
      {
        exact: true,
        path: '/administrativo/boletos',
        component: () => <Redirect to="/administrativo/boletos/todos" />
      },
      {
        exact: true,
        path: '/administrativo/boletos/todos',
        component: lazy(() =>
          import('src/views/area_administrativa/boletos/GerenciaBoletosView')
        )
      },
      {
        exact: true,
        path: '/administrativo/boletos/configuracoes',
        component: lazy(() =>
          import(
            'src/views/area_administrativa/boletos/configuracoes/ConfiguracoesBoletosView'
          )
        )
      },

      {
        exact: true,
        path: '/administrativo/notasdeservico',
        component: () => <Redirect to="/administrativo/notasdeservico/todas" />
      },
      {
        exact: true,
        path: '/administrativo/notasdeservico/todas',
        component: lazy(() =>
          import(
            'src/views/area_administrativa/notasDeServico/GerenciaNotasFiscaisDeServico'
          )
        )
      },
      {
        exact: true,
        path: '/administrativo/notasdeservico/configuracoes',
        component: lazy(() =>
          import(
            'src/views/area_administrativa/notasDeServico/configuracoes/ConfiguracoesNotasFiscaisDeServicoView'
          )
        )
      },
      {
        exact: true,
        path: '/administrativo/feriados',
        component: lazy(() =>
          import('src/views/area_administrativa/feriados/GerenciaFeriadosView')
        )
      },
      {
        exact: true,
        path: '/administrativo/feriados/:tab',
        component: lazy(() =>
          import('src/views/area_administrativa/feriados/GerenciaFeriadosView')
        )
      },
      {
        exact: true,
        path: '',
        component: () => <Redirect to="/administrativo/cartorios" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
