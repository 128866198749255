import axios from 'src/utils/axios';
import objFromArray from 'src/utils/objFromArray';
import createCrudReducer, {
  QueryRequestState
} from 'src/reducers/createCrudReducer';
import Anexo from 'src/model/Anexo';
import { Dispatch } from '@reduxjs/toolkit';

interface AnexosReducerState {
  anexosPorCartorio: Record<number, AnexoRequestState>;
}

interface Actions {
  getAnexosCartorio: (cartorioId: number) => (dispatch: Dispatch) => any;
}

interface AnexoRequestState extends QueryRequestState<number, Anexo> {}

const anexosReducer = createCrudReducer<
  number,
  Anexo,
  AnexosReducerState,
  Actions
>('anexos', 'anexos', {
  reducers: {
    getAnexosCartorioStarted(state, action) {
      const cartorioId = action.payload;
      state.anexosPorCartorio[cartorioId] = {
        ...state.anexosPorCartorio[cartorioId],
        loading: true
      };
    },
    getAnexosCartorioFailed(state, action) {
      const cartorioId = action.payload;
      state.anexosPorCartorio[cartorioId] = {
        ...state.anexosPorCartorio[cartorioId],
        loading: false,
        loaded: true
      };
    },
    getAnexosCartorioSuccess(state, action) {
      const { cartorioId, anexos } = action.payload;
      state.anexosPorCartorio[cartorioId] = {
        ...state.anexosPorCartorio[cartorioId],
        dataOrdered: anexos,
        dataById: objFromArray(anexos),
        loading: false,
        loaded: true
      };
    }
  },
  initialState: {
    anexosPorCartorio: {} as Record<string, AnexoRequestState>
  },
  actionsBuilder: (slice) => ({
    getAnexosCartorio: (cartorioId: number) => async (dispatch: Dispatch) => {
      try {
        dispatch(slice.actions.getAnexosCartorioStarted(cartorioId));
        const response = await axios.get(`anexos/cartorios/${cartorioId}`);
        dispatch(
          slice.actions.getAnexosCartorioSuccess({
            cartorioId,
            anexos: response.data
          })
        );
        return response.data;
      } catch (e) {
        dispatch(slice.actions.getAnexosCartorioFailed(cartorioId));
        return undefined;
      }
    }
  })
});
export const {
  actions,
  slice: { reducer }
} = anexosReducer;

export default anexosReducer;
