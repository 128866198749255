import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import objFromArray from 'src/utils/objFromArray';

const ibgeLocalidadesAxios = axios.create({
  baseURL: 'https://servicodados.ibge.gov.br/api/v1/localidades'
});

const slice = createSlice({
  name: 'ibgeLocalidades',
  initialState: {
    ufs: {
      loaded: false,
      loading: false,
      error: false
    },
    cidades: {},
    bairros: {}
  },
  reducers: {
    // eslint-disable-next-line no-unused-vars
    ufsLoading(state) {
      state = {
        ...state,
        loading: false
      };
    },
    ufsLoaded(state, action) {
      const ufs = action.payload;
      state.ufs = {
        ...state.ufs,
        dataOrdered: ufs,
        dataById: objFromArray(ufs),
        loaded: true,
        loading: false,
        error: false
      };
    },
    ufsError(state, action) {
      const error = action.payload;
      state = {
        ...state,
        loading: false,
        error
      };
    },
    cidadesLoading(state, action) {
      const codigoUf = action.payload;
      state.cidades[codigoUf] = {
        ...state.cidades[codigoUf],
        loading: true
      };
    },
    cidadesError(state, action) {
      const { codigoUf, error } = action.payload;
      state.cidades[codigoUf] = {
        ...state.cidades[codigoUf],
        loading: false,
        error
      };
    },
    cidadesLoaded(state, action) {
      const { codigoUf, cidades } = action.payload;
      state.cidades[codigoUf] = {
        ...state.cidades[codigoUf],
        dataOrdered: cidades,
        dataById: objFromArray(cidades),
        loaded: true,
        loading: false,
        error: false
      };
    },
    bairrosLoading(state, action) {
      const codigoCidade = action.payload;
      state.bairros[codigoCidade] = {
        ...state.bairros[codigoCidade],
        loading: true
      };
    },
    bairrosError(state, action) {
      const { codigoCidade, error } = action.payload;
      state.bairros[codigoCidade] = {
        ...state.bairros[codigoCidade],
        loading: false,
        error
      };
    },
    bairrosLoaded(state, action) {
      const { codigoCidade, bairros } = action.payload;
      state.bairros[codigoCidade] = {
        ...state.bairros[codigoCidade],
        dataOrdered: bairros,
        dataById: objFromArray(bairros),
        loaded: true,
        loading: false,
        error: false
      };
    }
  }
});

export const actions = {
  getUFs: () => async (dispatch) => {
    try {
      dispatch(slice.actions.ufsLoading());
      const response = await ibgeLocalidadesAxios.get('estados');
      dispatch(slice.actions.ufsLoaded(response.data));
      return response.data;
    } catch (e) {
      dispatch(slice.actions.ufsError(e));
      return undefined;
    }
  },
  getCidades: (codigoUf) => async (dispatch) => {
    try {
      dispatch(slice.actions.cidadesLoading(codigoUf));
      const response = await ibgeLocalidadesAxios.get(
        `estados/${codigoUf}/municipios`
      );
      dispatch(
        slice.actions.cidadesLoaded({
          codigoUf,
          cidades: response.data
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        slice.actions.cidadesError({
          codigoUf,
          error
        })
      );
      return undefined;
    }
  },
  getBairros: (codigoCidade) => async (dispatch) => {
    try {
      dispatch(slice.actions.bairrosLoading(codigoCidade));
      const response = await ibgeLocalidadesAxios.get(
        `municipios/${codigoCidade}/subdistritos`
      );
      dispatch(
        slice.actions.bairrosLoaded({
          codigoCidade,
          bairros: response.data
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        slice.actions.bairrosError({
          codigoCidade,
          error
        })
      );
      return undefined;
    }
  }
};

export const { reducer } = slice;
