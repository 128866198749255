import createCrudReducer from 'src/reducers/createCrudReducer';
import Cidade from 'src/model/Cidade';

const cidadesReducer = createCrudReducer<number, Cidade>(
  'cidades',
  'cidades'
);

export const {
  actions,
  slice: { reducer }
} = cidadesReducer;

export default cidadesReducer;
