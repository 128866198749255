import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import Cadastro from 'src/model/meu_cartorio/Cadastro';
import Fatura from 'src/model/Fatura';
import Servicos from 'src/model/meu_cartorio/Servico';
import { BaseRequestState } from 'src/reducers/createCrudReducer';

interface DataRequestState<T> extends BaseRequestState {
  data?: T;
}

const initialDataRequestState = {
  loading: false,
  loaded: false
};

interface MeuCartorioReducerState {
  cadastro: DataRequestState<Cadastro>;
  faturas: DataRequestState<Fatura[]>;
  servicos: DataRequestState<Servicos>;
}

const slice = createSlice<
  MeuCartorioReducerState,
  SliceCaseReducers<MeuCartorioReducerState>
>({
  name: 'meuCartorio',
  initialState: {
    cadastro: initialDataRequestState,
    faturas: initialDataRequestState,
    servicos: initialDataRequestState
  },
  reducers: {
    // eslint-disable-next-line no-unused-vars
    loading(state, action) {
      const property = action.payload;
      state[property] = {
        ...state[property],
        loading: true
      };
    },
    loaded(state, action) {
      const { property, data } = action.payload;
      state[property] = {
        ...state[property],
        loading: false,
        loaded: true,
        error: false,
        data
      };
    },
    error(state, action) {
      const { property, error } = action.payload;
      state[property] = {
        ...state[property],
        loading: false,
        loaded: true,
        error
      };
    }
  }
});

const getProperty = (property) => () => async (dispatch) => {
  try {
    dispatch(slice.actions.loading(property));
    const response = await axios.get(`MeuCartorio/${property}`);
    dispatch(slice.actions.loaded({ property, data: response.data }));
    return response.data;
  } catch (e) {
    dispatch(slice.actions.error({ property, error: true }));
    return undefined;
  }
};
export const actions = {
  getServicos: getProperty('servicos'),
  getFaturas: getProperty('faturas'),
  getCadastro: getProperty('cadastro')
};

export const { reducer } = slice;
