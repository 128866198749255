import { combineReducers } from '@reduxjs/toolkit';
import { reducer as cartorios } from 'src/reducers/cartoriosReducer';
import { reducer as funcionarios } from 'src/reducers/funcionariosCrudReducer';
import { reducer as faturas } from 'src/reducers/faturasReducer';
import { reducer as anexos } from 'src/reducers/anexosReducer';
import { reducer as imobAssinaturas } from 'src/reducers/imobAssinaturasReducer';
import { reducer as terminais } from 'src/reducers/terminaisReducer';
import { reducer as imports } from 'src/reducers/importsReducer';
import { reducer as backupAssinaturas } from 'src/reducers/backupAssinaturasReducer';
import { reducer as pagamentoConfigs } from 'src/reducers/pagamentoConfigsReducer';
import { reducer as ibgeLocalidades } from 'src/reducers/ibgeLocalidadesReducer';
import { reducer as processamentoDeFaturas } from 'src/reducers/processamentoDeFaturasReducer';
import { reducer as boletos } from 'src/reducers/boletosReducer';
import { reducer as notasFiscaisDeServico } from 'src/reducers/notasFiscaisDeServicoReducer';
import { reducer as meuCartorio } from 'src/reducers/meuCartorioReducer';
import { reducer as feriados } from 'src/reducers/feriadosReducer';
import { reducer as descontos } from 'src/reducers/descontosReducer';
import { reducer as estados } from 'src/reducers/estadosReducer';
import { reducer as cidades } from 'src/reducers/cidadesReducer';
import { reducer as notaFiscalEletronicaConfigs } from 'src/reducers/notaFiscalEletronicaConfigsReducer';
import { reducer as pagamentoExterno } from 'src/reducers/pagamentoExternoReducer';
import { Action } from 'redux';

const rootReducer = combineReducers({
  cartorios,
  funcionarios,
  faturas,
  anexos,
  imobAssinaturas,
  terminais,
  imports,
  backupAssinaturas,
  pagamentoConfigs,
  ibgeLocalidades,
  processamentoDeFaturas,
  boletos,
  notasFiscaisDeServico,
  notaFiscalEletronicaConfigs,
  meuCartorio,
  feriados,
  descontos,
  estados,
  cidades,
  pagamentoExterno

});

export default (state: any, action: Action) =>
  rootReducer(action.type === 'RESET_STATE' ? undefined : state, action);
