/* eslint-disable no-unused-vars */
import {
  createSlice,
  Dispatch,
  Slice,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import axios from 'src/utils/axios';
import stringifyQuery from 'src/utils/parsers/queryString';
import { asyncScheduler, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import objFromArray from 'src/utils/objFromArray';
import Query from 'src/model/Query';
import Model, { BaseKey } from 'src/model/Model';

export interface BaseRequestState {
  loading: boolean;
  loaded: boolean;
  error?: string;
}

export interface QueryRequestState<
  Key extends BaseKey,
  Resource extends Model<Key>
> extends BaseRequestState {
  dataById: Record<Key, Resource>;
  dataOrdered: Resource[];
  count: number;
}

interface ItemRequestState<Key extends BaseKey, Resource extends Model<Key>>
  extends BaseRequestState {
  data: Resource;
}

interface CountRequestState extends BaseRequestState {
  count: number;
}

export interface CrudState<
  Key extends BaseKey,
  Resource extends Model<Key>,
  Item extends Model<Key> = Resource
> {
  queryRequests: Record<string, QueryRequestState<Key, Resource>>;
  itemRequests: Record<string, ItemRequestState<Key, Item>>;
  countRequests: Record<string, CountRequestState>;
}

export interface CrudActions<
  Filter = any,
  Create = any,
  Key = number,
  Update = Create
> {
  connect: (query: Query<Filter>) => (dispatch: Dispatch) => any;
  get: (query?: Query<Filter>) => (dispatch: Dispatch) => any;
  count: (query: Query<Filter>) => (dispatch: Dispatch) => any;
  getById: (elementId: Key) => (dispatch: Dispatch) => any;
  create: (data: Create) => (dispatch: Dispatch) => any;
  createLote: (data: Create[]) => (dispatch: Dispatch) => any;
  update: (
    elementId: Key,
    updateRequest: Update
  ) => (dispatch: Dispatch) => any;
  delete: (elementId: Key) => (dispatch: Dispatch) => any;
}

function createCrudReducer<
  Key extends BaseKey,
  Resource extends Model<Key>,
  State = unknown,
  Actions = {},
  Item extends Model<Key> = Resource,
  Filter = Resource,
  Create = Resource,
  Update = Resource,
  FullState extends CrudState<Key, Resource, Item> & State = CrudState<
    Key,
    Resource,
    Item
  > &
    State
>(
  name: string,
  url: string,
  {
    initialState,
    reducers = {},
    actionsBuilder
  }: {
    initialState?: State;
    reducers?: SliceCaseReducers<FullState>;
    actionsBuilder?: (
      slice: Slice<FullState, SliceCaseReducers<FullState>>
    ) => Actions;
  } = {}
): {
  slice: Slice<FullState, SliceCaseReducers<FullState>>;
  actions: CrudActions<Filter, Create, Key, Update> & Actions;
} {
  const slice = createSlice<FullState, SliceCaseReducers<FullState>, any>({
    name,
    initialState: {
      queryRequests: {} as Record<string, QueryRequestState<Key, Resource>>,
      itemRequests: {} as Record<string, ItemRequestState<Key, Item>>,
      countRequests: {} as Record<string, CountRequestState>,
      ...initialState
    } as FullState,
    reducers: {
      countStarted(state, action) {
        const query = action.payload;
        const queryString = `?${stringifyQuery(query)}`;
        state.countRequests[queryString] = {
          ...state.countRequests[queryString],
          loading: true
        };
      },
      countFailed(state, action) {
        const query = action.payload;
        const queryString = `?${stringifyQuery(query)}`;

        state.countRequests[queryString] = {
          ...state.countRequests[queryString],
          error: 'Erro ao contar items em query',
          loading: false
        };
      },

      countSuccess(state, action) {
        const { query, data } = action.payload;
        const queryString = `?${stringifyQuery(query)}`;
        state.countRequests[queryString] = {
          ...state.countRequests[queryString],
          count: data,
          loading: false,
          loaded: true
        };
      },

      getStarted(state, action) {
        const query = action.payload;
        const queryString = `?${stringifyQuery(query)}`;
        state.queryRequests[queryString] = {
          ...state.queryRequests[queryString],
          loading: true
        };
      },
      getFailed(state, action) {
        const query = action.payload;
        const queryString = `?${stringifyQuery(query)}`;
        state.queryRequests[queryString] = {
          ...state.queryRequests[queryString],
          loading: false
        };
      },
      get(state, action) {
        const { query, data } = action.payload;

        const queryString = `?${stringifyQuery(query)}`;

        state.queryRequests[queryString] = {
          ...state.queryRequests[queryString],
          loading: false,
          loaded: true,
          dataOrdered: data,
          dataById: objFromArray<Key, Resource>(data) as any
        };
      },
      getByIdStarted(state, action) {
        const id = action.payload;
        state.itemRequests[id] = { ...state.itemRequests[id], loading: true };
      },
      getByIdFailed(state, action) {
        const id = action.payload;
        state.itemRequests[id] = {
          ...state.itemRequests[id],
          loading: false,
          loaded: true
        };
      },
      getById(state, action) {
        const data = action.payload;
        state.itemRequests[data.id] = {
          ...state.itemRequests[data.id],
          data,
          loading: false,
          loaded: true
        };
      },
      create(state, action) {
        const data = action.payload;
        state.itemRequests[data.id] = {
          ...state.itemRequests[data.id],
          data,
          loading: false,
          loaded: true
        };
      },
      createLote(state, action) {
        const dataList = action.payload;
        for (const data of dataList) {
          state.itemRequests[data.id] = {
            ...state.itemRequests[data.id],
            data,
            loading: false,
            loaded: true
          };
        }
      },
      update(state, action) {
        const data = action.payload;
        state.itemRequests[data.id] = {
          ...state.itemRequests[data.id],
          data,
          loading: false,
          loaded: true
        };
      },
      delete(state, action) {
        const elementId = action.payload;
        delete state.itemRequests[elementId];
      },
      ...reducers
    }
  });

  const get = (query?: Query<Filter>) => async (dispatch: Dispatch) => {
    try {
      console.log(`get ${url}`);
      dispatch(slice.actions.getStarted(query));
      const response = await axios.get(url + (query ? '/filtered' : ''), {
        params: { query }
      });
      dispatch(slice.actions.get({ data: response.data, query }));
      return response.data;
    } catch (e) {
      dispatch(slice.actions.getFailed(query));
      throw e;
    }
  };

  const count = (query: Query<Filter>) => async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.countStarted(query));
      const response = await axios.get(
        `${url + (query ? '/filtered' : '')}/count`,
        {
          params: { query }
        }
      );
      dispatch(slice.actions.countSuccess({ data: response.data, query }));
      return response.data;
    } catch (e) {
      dispatch(slice.actions.countFailed(query));
      throw e;
    }
  };

  return {
    slice,
    actions: {
      connect: (query: Query<Filter>) => async (dispatch: Dispatch) => {
        try {
          const connectionSubject = new Subject();
          const subjectSub = connectionSubject
            .pipe(
              throttleTime(3000, asyncScheduler, {
                leading: true,
                trailing: true
              })
            )
            .subscribe(async () => {
              await get(query)(dispatch);
              await count(query)(dispatch);
            });

          const connection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_API_URL}/hubs/${url}`)
            .configureLogging(LogLevel.Debug)
            .withAutomaticReconnect()
            .build();

          connection.on('Created', (resource) =>
            connectionSubject.next({ methodName: 'Created', resource })
          );

          connection.on('Updated', (resource) =>
            connectionSubject.next({ methodName: 'Updated', resource })
          );

          connection.on('Deleted', (resource) =>
            connectionSubject.next({ methodName: 'Deleted', resource })
          );

          await Promise.all([get(query)(dispatch), count(query)(dispatch)]);
          await connection.start();
          if (query) {
            await connection.invoke('ConnectToCollection');
          } else {
            await connection.invoke('ConnectToCollection');
          }
          return () => {
            subjectSub.unsubscribe();
            connectionSubject.unsubscribe();
            connection.stop();
          };
        } catch (e) {
          dispatch(slice.actions.getFailed(query));
          throw e;
        }
      },
      get,
      count,

      getById: (elementId: Key) => async (dispatch: Dispatch) => {
        try {
          dispatch(slice.actions.getByIdStarted(elementId));
          const response = await axios.get(`${url}/${elementId}`);
          dispatch(slice.actions.getById(response.data));
          return response.data;
        } catch (e) {
          dispatch(slice.actions.getByIdFailed(elementId));
          throw e;
        }
      },

      create: (data: Create) => async (dispatch: Dispatch) => {
        const response = await axios.post(url, data);
        dispatch(slice.actions.create(response.data));
        return response.data;
      },

      createLote: (data: Create[]) => async (dispatch: Dispatch) => {
        const response = await axios.post(`${url}/lote`, data);
        dispatch(slice.actions.createLote(response.data));
        return response.data;
      },

      update: (elementId: Key, updateRequest: Update) => async (
        dispatch: Dispatch
      ) => {
        await axios.put(`${url}/${elementId}`, updateRequest);
        const response = await axios.get(`${url}/${elementId}`);
        dispatch(slice.actions.update(response.data));
        return response.data;
      },

      delete: (elementId: Key) => async (dispatch: Dispatch) => {
        await axios.delete(`${url}/${elementId}`);
        dispatch(slice.actions.delete(elementId));
      },
      ...actionsBuilder?.(slice)
    } as CrudActions<Filter, Create, Key, Update> & Actions
  };
}

export default createCrudReducer;
