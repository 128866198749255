import axios from 'src/utils/axios';
import createCrudReducer from 'src/reducers/createCrudReducer';
import Fatura from 'src/model/Fatura';

const actionsBuilder = (slice) => ({
  gerarBoleto: (faturaId) => async (dispatch) => {
    await axios.post(`faturas/${faturaId}/boletos`);
    const response = await axios.get(`faturas/${faturaId}`);

    dispatch(slice.actions.update(response.data));
  },
  emitirNFSe: (faturaId) => async (dispatch) => {
    await axios.post(`faturas/${faturaId}/nfse`);
    const response = await axios.get(`faturas/${faturaId}`);

    dispatch(slice.actions.update(response.data));
  },
  reemitirNFSe: (faturaId, cancelamento) => async (dispatch) => {
    await axios.post(`faturas/${faturaId}/ReemitirNFSe`, cancelamento);
    const response = await axios.get(`faturas/${faturaId}`);

    dispatch(slice.actions.update(response.data));
  },
  alterarIgnorarMultaEJuros: (faturaId, ignorarMultaEJuros) => async (
    dispatch
  ) => {
    await axios.put(`faturas/${faturaId}/IgnorarMultaEJuros`, {
      ignorarMultaEJuros
    });
    const response = await axios.get(`faturas/${faturaId}`);
    dispatch(slice.actions.update(response.data));
  }
});

const faturasReducer = createCrudReducer<
  number,
  Fatura,
  {},
  ReturnType<typeof actionsBuilder>
>('faturas', 'faturas', {
  actionsBuilder
});
export const {
  actions,
  slice: { reducer }
} = faturasReducer;

export default faturasReducer;
