import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'src/utils/axios';
import uuidv4 from 'src/utils/uuidv4';
import arrayFromObj from '../utils/arrayFromObj';

const createImportsReducer = () => {
  const slice = createSlice({
    name: 'imports',
    initialState: {
      requests: [],
      requestsById: {}
    },
    reducers: {
      started(state, action) {
        const element = action.payload;
        state.itemRequestsById[element.guid] = element;
        state.itemRequests = arrayFromObj(state.itemRequestsById);
      },
      removed(state, action) {
        const element = action.payload;
        delete state.itemRequestsById[element.guid];
        state.itemRequests = arrayFromObj(state.itemRequestsById);
      },
      updated(state, action) {
        const element = action.payload;
        state.itemRequestsById[element.guid] = element;
        state.itemRequests = arrayFromObj(state.itemRequestsById);
      }
    }
  });

  return {
    slice,
    actions: {
      start: (url, data, info) => async (dispatch) => {
        let request = {
          guid: uuidv4(),
          data,
          response: null,
          info
        };

        dispatch(slice.actions.started(request));

        try {
          const response = await axiosInstance.post(url, data);

          request = { ...request, response };
          dispatch(slice.actions.updated(request));
        } catch (e) {
          request = { ...request, response: e.response };

          dispatch(slice.actions.updated(request));
        }
      },

      cancel: (element) => async (dispatch) => {
        dispatch(slice.actions.removed(element));
      },

      cancelAll: (requests) => async (dispatch) => {
        for (const r of requests) {
          dispatch(slice.actions.removed(r));
        }
      }
    }
  };
};

const importsReducer = createImportsReducer();
export const {
  actions,
  slice: { reducer }
} = importsReducer;

export default importsReducer;
