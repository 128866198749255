import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core';

import NavBar from 'src/components/Nav/NavBar';
import AppBar from 'src/components/AppBar';
import Box from '@material-ui/core/Box';
import Exports from 'src/layouts/DashboardLayout/Actions/Exports';
import Account from 'src/layouts/DashboardLayout/Actions/Account';
import StoreIcon from '@material-ui/icons/Store';
import { Users as UsersIcon } from 'react-feather';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DateRange } from '@material-ui/icons';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 54,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [appbarShadow, setAppbarShadow] = useState(false);

  return (
    <div className={classes.root}>
      <AppBar
        hasNavBar
        style={{
          width: !isLgUp ? '100%' : 'calc(100% - 256px)',
          backgroundColor: 'transparent',
          boxShadow: appbarShadow
            ? '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)'
            : 'none'
        }}
        onMobileNavOpen={() => setMobileNavOpen(true)}
        actions={
          <>
            <Box ml={2}>
              <Exports />
            </Box>
            <Box ml={2}>
              <Account />
            </Box>
          </>
        }
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        sections={[
          {
            subheader: 'Gerenciamento',
            items: [
              {
                title: 'Cartorios',
                icon: StoreIcon,
                href: '/administrativo/cartorios'
              },
              {
                title: 'Faturas',
                icon: DateRange,
                items: [
                  {
                    title: 'Todas',
                    href: '/administrativo/faturas/todas'
                  },
                  {
                    title: 'Processamento',
                    href: '/administrativo/faturas/processamentos'
                  },
                  {
                    title: 'Configurações',
                    href: '/administrativo/faturas/configuracoes'
                  }
                ]
              },
              // {
              //   title: 'Boletos',
              //   icon: AttachMoney,
              //   items: [
              //     {
              //       title: 'Todos',
              //       href: '/administrativo/boletos/todos'
              //     },
              //     {
              //       title: 'Configurações',
              //       href: '/administrativo/boletos/configuracoes'
              //     }
              //   ]
              // },
              // {
              //   title: 'Notas de serviço',
              //   icon: ReceiptIcon,
              //   items: [
              //     {
              //       title: 'Todas',
              //       href: '/administrativo/notasdeservico/todas'
              //     },
              //     {
              //       title: 'Configurações',
              //       href: '/administrativo/notasdeservico/configuracoes'
              //     }
              //   ]
              // },
              {
                title: 'Usuarios',
                icon: UsersIcon,
                href: '/administrativo/usuarios'
              },
              {
                title: 'Feriados',
                icon: BeachAccessIcon,
                href: '/administrativo/feriados'
              }
            ]
          }
        ]}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <PerfectScrollbar
              onScrollY={(container) => {
                if (container.scrollTop > 0 && !appbarShadow) {
                  setAppbarShadow(true);
                } else if (container.scrollTop === 0 && appbarShadow) {
                  setAppbarShadow(false);
                }
              }}
            >
              {children}
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
