import createCrudReducer from 'src/reducers/createCrudReducer';

const pagamentoConfigsReducer = createCrudReducer(
  'pagamentoConfigs',
  'pagamentoConfigs'
);
export const {
  actions,
  slice: { reducer }
} = pagamentoConfigsReducer;

export default pagamentoConfigsReducer;
