import createCrudReducer from 'src/reducers/createCrudReducer';

const notasFiscaisDeServicoReducer = createCrudReducer(
  'notasFiscaisDeServico',
  'notasFiscaisDeServico'
);
export const {
  actions,
  slice: { reducer }
} = notasFiscaisDeServicoReducer;
