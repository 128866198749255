import axios from 'src/utils/axios';
import createCrudReducer from 'src/reducers/createCrudReducer';

const backupAssinaturasReducer = createCrudReducer(
  'backupAssinaturas',
  'backupAssinaturas',
  {
    actionsBuilder: (slice) => ({
      sincronizarBackup: (backupId: number) => async (dispatch) => {
        await axios.post(`backupAssinaturas/${backupId}/sincronizacao`);
        const response = await axios.get(`backupAssinaturas/${backupId}`);
        dispatch(slice.actions.update(response.data));
      }
    })
  }
);
export const {
  actions,
  slice: { reducer }
} = backupAssinaturasReducer;

export default backupAssinaturasReducer;
