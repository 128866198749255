import createCrudReducer from 'src/reducers/createCrudReducer';
import Cartorio from 'src/model/Cartorio';

const cartoriosReducer = createCrudReducer<number, Cartorio>(
  'cartorios',
  'cartorios'
);

export const {
  actions,
  slice: { reducer }
} = cartoriosReducer;

export default cartoriosReducer;
