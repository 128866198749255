import React from 'react';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  SvgIcon,
  Toolbar
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import useTheme from '@material-ui/core/styles/useTheme';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100
  },
  toolbar: {
    minHeight: 54
  }
}));

const TopBar = ({
  className,
  hasNavBar,
  onMobileNavOpen,
  actions,
  ...rest
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      style={{ background: 'transparent', boxShadow: 'none' }}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        {hasNavBar && (
          <Hidden lgUp>
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon fontSize="small">
                <MenuIcon color={theme.palette.text.primary} />
              </SvgIcon>
            </IconButton>
          </Hidden>
        )}
        <Box ml={2} flexGrow={1} />
        {process.env.REACT_APP_ENVIRONMENT &&
          process.env.REACT_APP_ENVIRONMENT !== 'producao' && (
            <Box>
              <Chip
                color="primary"
                label={`Ambiente de ${process.env.REACT_APP_ENVIRONMENT}`}
              />
            </Box>
          )}
        <Box
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {actions}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
