import axios from 'axios';
import stringifyQuery from 'src/utils/parsers/queryString';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });
// Format nested params correctly
axiosInstance.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => stringifyQuery(params);

  return config;
});
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject({
      ...(typeof error.response?.data !== 'string' && error.response?.data),
      response: error.response
    })
);

export default axiosInstance;
