import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'src/utils/axios';

const slice = createSlice({
  name: 'notaFiscalEletronicaConfigs',
  initialState: {
    isLoading: false,
    isLoaded: false,
    config: null,
    error: null
  },
  reducers: {
    // eslint-disable-next-line no-unused-vars
    loading(state) {
      state.isLoading = true;
    },
    loaded(state, action) {
      const config = action.payload;
      state.isLoading = false;
      state.isLoaded = true;
      state.config = config;
    },
    error(state, action) {
      const error = action.payload;
      state.loading = false;
      state.error = error;
    }
  }
});

export const actions = {
  get: () => async (dispatch) => {
    try {
      dispatch(slice.actions.loading());
      const response = await axiosInstance.get('NotaFiscalEletronicaConfigs');
      dispatch(slice.actions.loaded(response.data));
      return response.data;
    } catch (e) {
      dispatch(slice.actions.error(e));
      throw e;
    }
  },
  create: (config) => async (dispatch) => {
    try {
      dispatch(slice.actions.loading());
      const response = await axiosInstance.post(
        'NotaFiscalEletronicaConfigs',
        config
      );
      dispatch(slice.actions.loaded(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.error(error));
      throw error;
    }
  }
};

export const { reducer } = slice;
