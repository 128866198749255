import createCrudReducer from 'src/reducers/createCrudReducer';
import { Desconto } from 'src/model/Fatura';

const terminaisReducer = createCrudReducer<number, Desconto>(
  'descontos',
  'descontos'
);
export const {
  actions,
  slice: { reducer }
} = terminaisReducer;

export default terminaisReducer;
