import createCrudReducer from 'src/reducers/createCrudReducer';
import { PagamentoExterno } from 'src/model/Fatura';

const pagamentoExternoReducer = createCrudReducer<number, PagamentoExterno>(
  'pagamentoExterno',
  'pagamentoExterno'
);
export const {
  actions,
  slice: { reducer }
} = pagamentoExternoReducer;

export default pagamentoExternoReducer;
